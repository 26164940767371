import GroupIcon from '@material-ui/icons/Group';
import RemoveFromQueueIcon from '@material-ui/icons/RemoveFromQueue';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import PersonAddIcon from '@material-ui/icons/PersonAdd';

export default [
  {
    title: 'MANAGEMENT',
    pages: [
      // -------------------------------------- Property --------------------------------------
      {
        title: 'Properties',
        href: '/manageProperty',
        icon: GroupIcon
      },
      {
        title: 'Subscriptions',
        href: '/manageSubscription',
        icon: RemoveFromQueueIcon
      },
      {
        title: 'Payment Logs',
        href: '/managePaymentlog',
        icon: LocalAtmIcon
      },
      {
        title: 'Requests Info',
        href: '/manageRquests',
        icon: PersonAddIcon
      },
    ]
  }
];
