/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import AuthLayout from './layouts/Auth';
import ErrorLayout from './layouts/Error';
import DashboardLayout from './layouts/Dashboard';
import OrganizationDashboardLayout from './layouts/OrganizationDashboard';
import RequireAuthOrg from './RequireAuthOrg';
import RequireAuthAdmin from './RequireAuthAdmin';
const AdminRoutes = [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/login" />
  },
  {
    path: '/errors',
    component: ErrorLayout,
    routes: [
      {
        path: '/errors/error-401',
        exact: true,
        component: lazy(() => import('views/Error401'))
      },
      {
        path: '/errors/error-404',
        exact: true,
        component: lazy(() => import('views/Error404'))
      },
      {
        path: '/errors/error-500',
        exact: true,
        component: lazy(() => import('views/Error500'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/',
    component: AuthLayout,
    routes: [
      {
        path: '/login',
        exact: true,
        component: lazy(() => import('views/Login'))
      },
      {
        path: '/forgotpassword',
        exact: true,
        component: lazy(() => import('views/AdminForgotPassword'))
      },
      {
        path: '/resetpwd/:token',
        exact: true,
        component: lazy(() => import('views/AdminResetPassword'))
      },
      {
        route: '/',
        component: DashboardLayout,
        routes: [
          // *************************  Manage Organization  ************************
          {
            path: '/manageOrganization',
            exact: true,
            component: RequireAuthAdmin(
              lazy(() => import('views/ManageOrganization'))
            )
          },

          {
            path: '/manageOrganization/organizationAdd',
            exact: true,
            component: RequireAuthAdmin(
              lazy(() => import('views/OrganizationAdd'))
            )
          },

          {
            path: '/manageOrganization/organizationDetails/subscriptionAdd/:id',
            exact: true,
            component: RequireAuthAdmin(
              lazy(() => import('views/AddOrgSubscriptionPlans'))
            )
          },
          {
            path: '/manageOrganization/organizationDetails/paymentLogAdd/:id',
            exact: true,
            component: RequireAuthAdmin(
              lazy(() => import('views/AddOrgPaymentLog'))
            )
          },
          {
            path:
              '/manageOrganization/organizationDetails/subscriptionEdit/:id',
            exact: true,
            component: RequireAuthAdmin(
              lazy(() => import('views/EditOrgSubscriptionPlan'))
            )
          },

          {
            path: '/manageOrganization/organizationDetails/paymentLogEdit/:id',
            exact: true,
            component: RequireAuthAdmin(
              lazy(() => import('views/EditOrgPaymentLog'))
            )
          },
          {
            path: '/manageOrganization/organizationEdit/:id',
            exact: true,
            component: RequireAuthAdmin(
              lazy(() => import('views/OrganizationEdit'))
            )
          },
          {
            path: '/manageOrganization/organizationDetails/:id',
            exact: true,
            component: RequireAuthAdmin(
              lazy(() => import('views/OrganizationDetails'))
            )
          },

          // *************************  Manage User  ************************
          {
            path: '/manageUser',
            exact: true,
            component: RequireAuthAdmin(lazy(() => import('views/ManageUser')))
          },
          {
            path: '/manageUser/userDetails/:id',
            exact: true,
            component: RequireAuthAdmin(lazy(() => import('views/UserDetails')))
          },
          {
            path: '/manageUser/userEdit/:id',
            exact: true,
            component: RequireAuthAdmin(lazy(() => import('views/UserEdit')))
          },
          {
            path: '/manageUser/userAdd',
            exact: true,
            component: RequireAuthAdmin(lazy(() => import('views/UserAdd')))
          },

          // *************************  Promocodes  ************************
          {
            path: '/promocodes',
            exact: true,
            component: RequireAuthAdmin(lazy(() => import('views/Promocodes')))
          },
          {
            path: '/promocodes/add',
            exact: true,
            component: RequireAuthAdmin(
              lazy(() => import('views/AddPromocodes'))
            )
          },
          {
            path: '/promocodes/editPromocode/:id',
            exact: true,
            component: RequireAuthAdmin(
              lazy(() => import('views/EditPromocode'))
            )
          },

          // **********************  Subscription Plans  ********************
          // {
          //   path: '/subscriptionPlans',
          //   exact: true,
          //   component: RequireAuthAdmin(lazy(() => import('views/SubscriptionPlans')))
          // },
          // {
          //   path: '/subscriptionPlans/editSubscriptionPlan/:id',
          //   exact: true,
          //   component: RequireAuthAdmin(lazy(() => import('views/EditSubscriptionPlan')))
          // },
          // {
          //   path: '/subscriptionPlans/add',
          //   exact: true,
          //   component: RequireAuthAdmin(lazy(() => import('views/AddSubscriptionPlans')))
          // },

          {
            path: '/subscriptionPlanUpdata',
            exact: true,
            component: RequireAuthAdmin(
              lazy(() => import('views/SubscriptionPlanUpdata'))
            )
          },
          {
            path: '/subscriptionPlanUpdata/editSubscriptionPlan/:id',
            exact: true,
            component: RequireAuthAdmin(
              lazy(() => import('views/EditSubscriptionPlan'))
            )
          },
          {
            path: '/subscriptionPlans/add',
            exact: true,
            component: RequireAuthAdmin(
              lazy(() => import('views/AddSubscriptionPlans'))
            )
          },

          // **********************  Boost SubscriptionPlan Plans  ********************
          {
            path: '/BoostSubscriptionPlan',
            exact: true,
            component: RequireAuthAdmin(
              lazy(() => import('views/BoostSubscriptionPlan'))
            )
          },
          {
            path: '/BoostSubscriptionPlan/EditBoostSubscriptionPlan/:id',
            exact: true,
            component: RequireAuthAdmin(
              lazy(() => import('views/EditBoostSubscriptionPlan'))
            )
          },
          {
            path: '/BoostSubscriptionPlan/add',
            exact: true,
            component: RequireAuthAdmin(
              lazy(() => import('views/AddBoostSubscriptionPlans'))
            )
          },
          // **********************  Payment Subscription  *********************
          {
            path: '/paymentSubscription',
            exact: true,
            component: RequireAuthAdmin(
              lazy(() => import('views/PaymentSubscription'))
            )
          },

          // **********************  Manage Report  *********************
          {
            path: '/manageReport',
            exact: true,
            component: RequireAuthAdmin(
              lazy(() => import('views/ManageReport'))
            )
          },

          // **********************  Property  *********************
          {
            path: '/property',
            exact: true,
            component: RequireAuthAdmin(
              lazy(() => import('views/PropertyAdmin'))
            )
          },
          {
            path: '/property/propertyDetailsAdmin/:id',
            exact: true,
            component: RequireAuthAdmin(
              lazy(() => import('views/PropertyDetailsAdmin'))
            )
          },
          // **********************  Manage Report  *********************
          {
            path: '/settings',
            exact: true,
            component: RequireAuthAdmin(
              lazy(() => import('views/Settings'))
            )
          },
          //
          // ******************************  Blog  ******************************
          {
            path: '/manage/blog',
            exact: true,
            component: RequireAuthAdmin(lazy(() => import('views/Blog')))
          },
          {
            path: '/blog/add',
            exact: true,
            component: RequireAuthAdmin(lazy(() => import('views/AddBlog')))
          },
          {
            path: '/blog/editBlog/:id',
            exact: true,
            component: RequireAuthAdmin(lazy(() => import('views/EditBlog')))
          },

          //
          // ******************************  CMS  ******************************
          {
            path: '/manage/cms',
            exact: true,
            component: RequireAuthAdmin(lazy(() => import('views/CMS')))
          },
          {
            path: '/cms/editCMS/:id',
            exact: true,
            component: RequireAuthAdmin(lazy(() => import('views/EditCMS')))
          },

          // *************************  Testimonials  ************************
          {
            path: '/manage/testimonials',
            exact: true,
            component: RequireAuthAdmin(
              lazy(() => import('views/Testimonials'))
            )
          },
          {
            path: '/testimonials/add',
            exact: true,
            component: RequireAuthAdmin(
              lazy(() => import('views/AddTestimonials'))
            )
          },
          {
            path: '/testimonials/editTestimonials/:id',
            exact: true,
            component: RequireAuthAdmin(
              lazy(() => import('views/EditTestimonials'))
            )
          },

          // *************************  University  ************************
          {
            path: '/manage/university',
            exact: true,
            component: RequireAuthAdmin(
              lazy(() => import('views/ManageUniversity'))
            )
          },
          {
            path: '/university/add',
            exact: true,
            component: RequireAuthAdmin(
              lazy(() => import('views/AddUniversity'))
            )
          },
          {
            path: '/university/editUniversity/:id',
            exact: true,
            component: RequireAuthAdmin(
              lazy(() => import('views/EditUniversity'))
            )
          },
          {
            component: () => <Redirect to="/errors/error-404" />
          }
        ]
      }
    ]
  },
  ,
  {
    path: '/errors',
    component: ErrorLayout,
    routes: [
      {
        path: '/errors/error-401',
        exact: true,
        component: lazy(() => import('views/Error401'))
      },
      {
        path: '/errors/error-404',
        exact: true,
        component: lazy(() => import('views/Error404'))
      },
      {
        path: '/errors/error-500',
        exact: true,
        component: lazy(() => import('views/Error500'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  }
];

export default AdminRoutes;
