import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import PersonIcon from '@material-ui/icons/PersonOutlined';
import MoneyOutlinedIcon from '@material-ui/icons/MoneyOutlined';
export default [
  {
    title: 'MANAGEMENT',
    pages: [
      // -------------------------------------- Organization --------------------------------------
      {
        title: 'Manage Users',
        href: '/manageOrganizationUser',
        icon: PersonIcon
      },
      {
        title: 'Payment Logs',
        href: '/paymentLogs',
        icon: MoneyOutlinedIcon
      },
      {
        title: 'Manage Groups',
        href: '/manageGroups',
        icon: PeopleIcon
      },
    ]
  }
];
