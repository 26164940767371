/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import AuthLayout from './layouts/Auth';
import ErrorLayout from './layouts/Error';
import DashboardLayout from './layouts/Dashboard';
import OrganizationDashboardLayout from './layouts/OrganizationDashboard';
import RequireAuthOrg from './RequireAuthOrg';
import RequireAuthAdmin from './RequireAuthAdmin';
const OrganizationRoutes = [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/login" />
  },
  {
    path: '/errors',
    component: ErrorLayout,
    routes: [
      {
        path: '/errors/error-401',
        exact: true,
        component: lazy(() => import('views/Error401'))
      },
      {
        path: '/errors/error-404',
        exact: true,
        component: lazy(() => import('views/Error404'))
      },
      {
        path: '/errors/error-500',
        exact: true,
        component: lazy(() => import('views/Error500'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/',
    component: AuthLayout,
    routes: [
      {
        path: '/login',
        exact: true,
        component: lazy(() => import('views/LoginOrganization'))
      },
      {
        path: '/forgotpassword',
        exact: true,
        component: lazy(() => import('views/ForgotPassword'))
      },
      {
        path: '/resetpwd/:token',
        exact: true,
        component: lazy(() => import('views/ResetPassword'))
      },
      {
        path: '/',
        component: OrganizationDashboardLayout,
        routes: [
          // *************************  Manage OrganizationUser  ************************
          {
            path: '/manageOrganizationUser',
            exact: true,
            component: RequireAuthOrg(lazy(() => import('views/ManageOrganizationUser')))
          },
          {
            path: '/manageOrganizationUser/organizationUserDetails/:id',
            exact: true,
            component: RequireAuthOrg(lazy(() => import('views/OrganizationUserDetails')))
          },
          {
            path: '/manageOrganizationUser/organizationUserAdd',
            exact: true,
            component: RequireAuthOrg(lazy(() => import('views/OrganizationUserAdd')))
          },
          {
            path: '/manageOrganizationUser/organizationUserEdit/:id',
            exact: true,
            component: RequireAuthOrg(lazy(() => import('views/OrganizationUserEdit')))
          },

          // ********************************  Payment Logs  *******************************
          {
            path: '/paymentLogs',
            exact: true,
            component: RequireAuthOrg(lazy(() => import('views/PaymentLogs')))
          },


          // ********************************  Manage Groups  *******************************
          {
            path: '/manageGroups',
            exact: true,
            component: RequireAuthOrg(lazy(() => import('views/ManageGroups')))
          },
          {
            component: () => <Redirect to="/errors/error-404" />
          }
        ]
      }
    ]
  }
];

export default OrganizationRoutes;
