/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import AuthLayout from './layouts/Auth';
import ErrorLayout from './layouts/Error';
import DashboardLayout from './layouts/Dashboard';
import OrganizationDashboardLayout from './layouts/OrganizationDashboard';
import RequireAuthOrg from './RequireAuthOrg';
import RequireAuthAdmin from './RequireAuthAdmin';
const routes = [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/login" />
  },
  {
    path: '/admin',
    exact: true,
    component: () => <Redirect to="/admin/login" />
  },
  // {
  //   path: '/',
  //   exact: true,
  //   component: () => <Redirect to="/organization/manageOrganizationUser" />
  // },

  {
    path: '/admin',
    component: AuthLayout,
    routes: [
      {
        path: '/admin/login',
        exact: true,
        component: lazy(() => import('views/Login'))
      },
      {
        route: '/admin',
        component: DashboardLayout,
        routes: [
          // *************************  Manage Organization  ************************
          {
            path: '/admin/manageOrganization',
            exact: true,
            component: RequireAuthAdmin(
              lazy(() => import('views/ManageOrganization'))
            )
          },

          {
            path: '/admin/manageOrganization/organizationAdd',
            exact: true,
            component: RequireAuthAdmin(
              lazy(() => import('views/OrganizationAdd'))
            )
          },

          {
            path:
              '/admin/manageOrganization/organizationDetails/subscriptionAdd/:id',
            exact: true,
            component: RequireAuthAdmin(
              lazy(() => import('views/AddOrgSubscriptionPlans'))
            )
          },
          {
            path:
              '/admin/manageOrganization/organizationDetails/paymentLogAdd/:id',
            exact: true,
            component: RequireAuthAdmin(
              lazy(() => import('views/AddOrgPaymentLog'))
            )
          },
          {
            path:
              '/admin/manageOrganization/organizationDetails/subscriptionEdit/:id',
            exact: true,
            component: RequireAuthAdmin(
              lazy(() => import('views/EditOrgSubscriptionPlan'))
            )
          },

          {
            path:
              '/admin/manageOrganization/organizationDetails/paymentLogEdit/:id',
            exact: true,
            component: RequireAuthAdmin(
              lazy(() => import('views/EditOrgPaymentLog'))
            )
          },
          {
            path: '/admin/manageOrganization/organizationEdit/:id',
            exact: true,
            component: RequireAuthAdmin(
              lazy(() => import('views/OrganizationEdit'))
            )
          },
          {
            path: '/admin/manageOrganization/organizationDetails/:id',
            exact: true,
            component: RequireAuthAdmin(
              lazy(() => import('views/OrganizationDetails'))
            )
          },

          // *************************  Manage User  ************************
          {
            path: '/admin/manageUser',
            exact: true,
            component: RequireAuthAdmin(lazy(() => import('views/ManageUser')))
          },
          {
            path: '/admin/manageUser/userDetails/:id',
            exact: true,
            component: RequireAuthAdmin(lazy(() => import('views/UserDetails')))
          },
          {
            path: '/admin/manageUser/userEdit/:id',
            exact: true,
            component: RequireAuthAdmin(lazy(() => import('views/UserEdit')))
          },
          {
            path: '/admin/manageUser/userAdd',
            exact: true,
            component: RequireAuthAdmin(lazy(() => import('views/UserAdd')))
          },

          // *************************  Promocodes  ************************
          {
            path: '/admin/promocodes',
            exact: true,
            component: RequireAuthAdmin(lazy(() => import('views/Promocodes')))
          },
          {
            path: '/admin/promocodes/add',
            exact: true,
            component: RequireAuthAdmin(
              lazy(() => import('views/AddPromocodes'))
            )
          },

          // **********************  Subscription Plans  ********************
          // {
          //   path: '/admin/subscriptionPlans',
          //   exact: true,
          //   component: RequireAuthAdmin(lazy(() => import('views/SubscriptionPlans')))
          // },
          // {
          //   path: '/admin/subscriptionPlans/editSubscriptionPlan/:id',
          //   exact: true,
          //   component: RequireAuthAdmin(lazy(() => import('views/EditSubscriptionPlan')))
          // },
          // {
          //   path: '/admin/subscriptionPlans/add',
          //   exact: true,
          //   component: RequireAuthAdmin(lazy(() => import('views/AddSubscriptionPlans')))
          // },

          {
            path: '/admin/subscriptionPlanUpdata',
            exact: true,
            component: RequireAuthAdmin(
              lazy(() => import('views/SubscriptionPlanUpdata'))
            )
          },
          {
            path: '/admin/subscriptionPlanUpdata/editSubscriptionPlan/:id',
            exact: true,
            component: RequireAuthAdmin(
              lazy(() => import('views/EditSubscriptionPlan'))
            )
          },
          {
            path: '/admin/subscriptionPlans/add',
            exact: true,
            component: RequireAuthAdmin(
              lazy(() => import('views/AddSubscriptionPlans'))
            )
          },
          // **********************  bscriptionPlan Plans  ********************
          {
            path: '/admin/BoostSubscriptionPlan',
            exact: true,
            component: RequireAuthAdmin(
              lazy(() => import('views/BoostSubscriptionPlan'))
            )
          },
          {
            path: '/admin/BoostSubscriptionPlan/EditBoostSubscriptionPlan/:id',
            exact: true,
            component: RequireAuthAdmin(
              lazy(() => import('views/EditBoostSubscriptionPlan'))
            )
          },
          {
            path: '/admin/BoostSubscriptionPlan/add',
            exact: true,
            component: RequireAuthAdmin(
              lazy(() => import('views/AddBoostSubscriptionPlans'))
            )
          },

          // **********************  Payment Subscription  *********************
          {
            path: '/admin/paymentSubscription',
            exact: true,
            component: RequireAuthAdmin(
              lazy(() => import('views/PaymentSubscription'))
            )
          },
          // **********************  Payment Subscription  *********************
          {
            path: '/admin/Settings',
            exact: true,
            component: RequireAuthAdmin(
              lazy(() => import('views/Settings'))
            )
          },
          // ******************************  CMS  ******************************
          {
            path: '/admin/manage/cms',
            exact: true,
            component: RequireAuthAdmin(lazy(() => import('views/CMS')))
          },
          {
            path: '/admin/cms/editCMS/:id',
            exact: true,
            component: RequireAuthAdmin(lazy(() => import('views/EditCMS')))
          },

          // *************************  Testimonials  ************************
          {
            path: '/admin/manage/testimonials',
            exact: true,
            component: RequireAuthAdmin(
              lazy(() => import('views/Testimonials'))
            )
          },
          {
            path: '/admin/testimonials/add',
            exact: true,
            component: RequireAuthAdmin(
              lazy(() => import('views/AddTestimonials'))
            )
          },
          {
            path: '/admin/testimonials/editTestimonials/:id',
            exact: true,
            component: RequireAuthAdmin(
              lazy(() => import('views/EditTestimonials'))
            )
          },
          {
            component: () => <Redirect to="/errors/error-404" />
          }
        ]
      }
    ]
  },
  ,
  {
    path: '/errors',
    component: ErrorLayout,
    routes: [
      {
        path: '/errors/error-401',
        exact: true,
        component: lazy(() => import('views/Error401'))
      },
      {
        path: '/errors/error-404',
        exact: true,
        component: lazy(() => import('views/Error404'))
      },
      {
        path: '/errors/error-500',
        exact: true,
        component: lazy(() => import('views/Error500'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/',
    component: AuthLayout,
    routes: [
      {
        path: '/login',
        exact: true,
        component: lazy(() => import('views/LoginOrganization'))
      },
      {
        path: '/',
        component: OrganizationDashboardLayout,
        routes: [
          // *************************  Manage OrganizationUser  ************************
          {
            path: '/manageOrganizationUser',
            exact: true,
            component: RequireAuthOrg(
              lazy(() => import('views/ManageOrganizationUser'))
            )
          },
          {
            path: '/manageOrganizationUser/organizationUserDetails/:id',
            exact: true,
            component: RequireAuthOrg(
              lazy(() => import('views/OrganizationUserDetails'))
            )
          },
          {
            path: '/manageOrganizationUser/organizationUserAdd',
            exact: true,
            component: RequireAuthOrg(
              lazy(() => import('views/OrganizationUserAdd'))
            )
          },
          {
            path: '/manageOrganizationUser/organizationUserEdit/:id',
            exact: true,
            component: RequireAuthOrg(
              lazy(() => import('views/OrganizationUserEdit'))
            )
          },

          // ********************************  Payment Logs  *******************************
          {
            path: '/paymentLogs',
            exact: true,
            component: RequireAuthOrg(lazy(() => import('views/PaymentLogs')))
          },

          // ********************************  Manage Groups  *******************************
          {
            path: '/manageGroups',
            exact: true,
            component: RequireAuthOrg(lazy(() => import('views/ManageGroups')))
          },
          {
            component: () => <Redirect to="/errors/error-404" />
          }
        ]
      }
    ]
  }
];

export default routes;
