export default {
  root: {
    '& .MuiOutlinedInput-input': {
      fontWeight: 500,
      fontSize: "16px"
    }

  },
  notchedOutline: {
    borderColor: 'rgba(0,0,0,0.15)',
    borderRadius: "10px",
  },

};
