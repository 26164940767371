import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Grid, Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { Link as RouterLink } from 'react-router-dom';

import { Search } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    marginBottom: '5px',
    padding: '25px 25px 8px 25px',
    borderRadius: '10px'
  },
  search: {
    flexGrow: 1,
    // [theme.breakpoints.up('lg')]: {
    //   width: "600px",
    // },
    // [theme.breakpoints.down('md')]: {
    //   width: "450px",
    // },
    width: '100%',
    flexBasis: 480
  },
  addButton: {
    paddingRight: '15px',
    padding: '8px',
    minWidth: '84px'
    //backgroundImage: "linear-gradient(113.93deg, #FD3576 -12.05%, #FF695B 56.1%)",
  },
  filterIcon: {
    marginRight: theme.spacing(1)
    //
  }
}));

const SearchBar = props => {
  const { addUrl, add, onFilter, onSearch, className, ...rest } = props;

  const classes = useStyles();

  // const [openFilter, setOpenFilter] = useState(false);

  // const handleFilterOpen = () => {
  //   setOpenFilter(true);
  // };

  // const handleFilterClose = () => {
  //   setOpenFilter(false);
  // };

  return (
    <Grid
      {...rest}
      className={clsx(classes.root, className)}
      container
      spacing={3}>
      <Grid item xs={8} sm={8} md={6} lg={5} xl={5}>
        <Search className={classes.search} onSearch={onSearch} />
      </Grid>
      {/* {add && (
        <Grid
          item
          xs={4}
          sm={4}
          md={6}
          lg={7}
          xl={7}
          style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            className={classes.addButton}
            color="primary"
            to={addUrl}
            component={RouterLink}
            size="small"
            variant="contained">
            <AddIcon className={classes.filterIcon} />
            Add
          </Button>
        </Grid>
      )} */}

      <Grid
        item
        xs={4}
        sm={4}
        md={6}
        lg={7}
        xl={7}
        style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          className={classes.addButton}
          color="primary"
          to={addUrl}
          component={RouterLink}
          size="small"
          variant="contained">
          <AddIcon className={classes.filterIcon} />
          Add
        </Button>
      </Grid>

      {/* <Filter
        onClose={handleFilterClose}
        onFilter={onFilter}
        open={openFilter}
      /> */}
    </Grid>
  );
};

SearchBar.propTypes = {
  className: PropTypes.string,
  onFilter: PropTypes.func,
  onSearch: PropTypes.func
};

export default SearchBar;
