import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Container, Toolbar } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none',
    // background: 'linear-gradient(349.17deg, #FD3576 -34.22%, #FF695B 98.87%)',
  },
  logoMatchup: {
    width: "220px",
    [theme.breakpoints.down("xs")]: {
      width: "130%",
    },
    marginTop: "5px",
  },
}));

const Topbar = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
      color="primary"
    >
      <Container maxWidth="lg">
        <Toolbar>
          <RouterLink to="/">
            <img
              alt="Logo"
              src="/images/loginBarLogo.svg"
              className={classes.logoMatchup}
            />
          </RouterLink>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string
};

export default Topbar;
