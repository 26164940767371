import React from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import MomentUtils from '@date-io/moment';
import { Provider as StoreProvider } from 'react-redux';
import { ThemeProvider } from '@material-ui/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { renderRoutes } from 'react-router-config';

import theme from './theme';
import { configureStore } from './store';
import routes from './routes';
import {
  ScrollReset,
  GoogleAnalytics,
  CookiesNotification
} from './components';
import './mixins/chartjs';
import './mixins/moment';
import './mixins/validate';
import './mixins/prismjs';
import './mock';
import './assets/scss/index.scss';
import AdminRoutes from './AdminRoutes';
import OrganizationRoutes from './OrganizationRoutes';
import PropertyRoutes from './PropertyRoutes';

const history = createBrowserHistory();
const store = configureStore();

const App = () => {

  const host = window.location.host;

  var host_routes = [];
  if (host !== process.env.REACT_APP_ADMIN_URL) {
    host_routes = AdminRoutes;
  } else if (host == process.env.REACT_APP_ORGANIZATION_URL) {
    host_routes = OrganizationRoutes;
  } else if (host == process.env.REACT_APP_PROPERTY_URL) {
    host_routes = PropertyRoutes;
  }

  return (
    <StoreProvider store={store}>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Router history={history}>
            <ScrollReset />
            <GoogleAnalytics />
            {/* <CookiesNotification /> */}
            {renderRoutes(host_routes)}
          </Router>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </StoreProvider>
  );
};

export default App;
