/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import AuthLayout from './layouts/Auth';
import ErrorLayout from './layouts/Error';
import DashboardLayout from './layouts/Dashboard';
import OrganizationDashboardLayout from './layouts/OrganizationDashboard';
import RequireAuthOrg from './RequireAuthOrg';
import RequireAuthAdmin from './RequireAuthAdmin';
import RequireAuthProperty from 'RequireAuthProperty';
import PropertyDashboard from 'layouts/PropertyDashboard';
const PropertyRoutes = [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/property/login" />
  },
  {
    path: '/errors',
    component: ErrorLayout,
    routes: [
      {
        path: '/errors/error-401',
        exact: true,
        component: lazy(() => import('views/Error401'))
      },
      {
        path: '/errors/error-404',
        exact: true,
        component: lazy(() => import('views/Error404'))
      },
      {
        path: '/errors/error-500',
        exact: true,
        component: lazy(() => import('views/Error500'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/property',
    component: AuthLayout,
    routes: [
      // {
      //   path: '/loginNew',
      //   exact: true,
      //   component: lazy(() => import('views/PropertySignupDetails'))
      // },
      {
        path: '/property/login',
        exact: true,
        component: lazy(() => import('views/LoginProperty'))
      },
      {
        path: '/property/signup',
        exact: true,
        component: lazy(() => import('views/SignupProperty'))
      },
      {
        path: '/property/forgotpassword',
        exact: true,
        component: lazy(() => import('views/PropertyForgotPassword'))
      },
      {
        path: '/resetpwd/:token',
        exact: true,
        component: lazy(() => import('views/PropertyResetPassword'))
      },
    ]
  },
  {
    path: '/',
    component: PropertyDashboard,
    routes: [

      // *************************  Manage Property ************************
      {
        path: '/manageProperty',
        exact: true,
        // component: RequireAuthProperty(lazy(() => import('views/ManageProperty')))
        component: lazy(() => import('views/ManageProperty'))
      },
      {
        path: '/manageProperty/propertyDetails/:id',
        exact: true,
        component: lazy(() => import('views/PropertyDetails'))
      },
      {
        path: '/manageProperty/addProperty',
        exact: true,
        // component: RequireAuthOrg(lazy(() => import('views/AddProperty')))
        component: lazy(() => import('views/AddProperty'))
      },
      {
        path: '/manageProperty/editProperty/:id',
        exact: true,
        component: lazy(() => import('views/PropertyEdit'))
      },

      // *************************  Manage Rquests ************************
      {
        path: '/manageRquests',
        exact: true,
        // component: RequireAuthProperty(lazy(() => import('views/ManageProperty')))
        component: lazy(() => import('views/ManageRequests'))
      },
      {
        path: '/viewYourProfile/:id',
        exact: true,
        component: lazy(() => import('views/ViewYourProfile'))
      },
      {
        path: '/your-profile',
        exact: true,
        component: lazy(() => import('views/ViewProfile'))
      },
      {
        path: '/changepassword',
        exact: true,
        component: lazy(() => import('views/Changepassword'))
      },

      // *************************  Manage Paymentlog ************************
      {
        path: '/managePaymentlog',
        exact: true,
        // component: RequireAuthProperty(lazy(() => import('views/ManageProperty')))
        component: lazy(() => import('views/PropertyPaymentLogs'))
      },

      // *************************  Manage Subscription ************************
      {
        path: '/manageSubscription',
        exact: true,
        // component: RequireAuthProperty(lazy(() => import('views/ManageProperty')))
        component: lazy(() => import('views/ManagePropertySubscription'))
      },

      // 
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  }
  ,
  {
    path: '/errors',
    component: ErrorLayout,
    routes: [
      {
        path: '/errors/error-401',
        exact: true,
        component: lazy(() => import('views/Error401'))
      },
      {
        path: '/errors/error-404',
        exact: true,
        component: lazy(() => import('views/Error404'))
      },
      {
        path: '/errors/error-500',
        exact: true,
        component: lazy(() => import('views/Error500'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  }

];

export default PropertyRoutes;
